export default function Statement() {
  return (
    <section
      className="statement-area"
      style={{ zIndex: 10, position: 'relative' }}
    >
      <div className="container">
        <div className="statement-content-wrap bg-default">
          <h2 className="section-heading">
            Reduce your heating & cooling costs by up to
          </h2>
          <div className="value-desc">
            <span className="value">$287</span>
            <p className="statement-desc">
              Optiwatt helps you save $287/year on average by reducing wasteful
              heating and cooling costs without compromising your comfort.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
