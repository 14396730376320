export default function Integrity() {
  return (
    <>
      {/* <!--integrity area start  --> */}
      <section className="integrity section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2 className="section-heading">
                <span className="gradient-title"> Free forever.</span> <br />
                No tricks.
                <br />
                No gimmicks.
              </h2>
            </div>
            <div className="col-md-7 col-lg-7 integrity-card-wrap">
              <img
                className="arrow-1 arrow"
                src="landing-assets/thermostat/img/inte/arrow-1.png"
                alt="arrow"
              />

              <div className="integrity-card integrity-card-1">
                <img
                  className="mobile-arrow-1 d-md-none"
                  src="landing-assets/thermostat/img/inte/arrow-m-1.png"
                  alt=""
                />
                <div className="integrity-card-icon">
                  <img
                    src="landing-assets/thermostat/img/inte/light-icon.png"
                    alt="icon"
                  />
                </div>
                <div className="integrity-card-text">
                  <h4 className="integrity-card-title">Electric Company</h4>
                  <p className="integrity-card-desc d-none d-md-block">
                    Optiwatt helps companies purchase electricity at the right
                    time to maximize savings.
                  </p>
                </div>
                <p className="integrity-card-desc-m d-md-none">
                  Optiwatt helps companies purchase electricity at the right
                  time to maximize savings.
                </p>
              </div>
            </div>

            <div className="col-md-12 col-lg-7 integrity-card-wrap">
              <div className="integrity-card integrity-card-2">
                <img
                  className="mobile-arrow-2 d-md-none"
                  src="landing-assets/thermostat/img/inte/arrow-m-2.png"
                  alt=""
                />
                <div className="integrity-card-icon">
                  <img
                    src="landing-assets/thermostat/img/inte/perosn.png"
                    alt="icon"
                  />
                </div>
                <div className="integrity-card-text">
                  <h4 className="integrity-card-title">You</h4>
                  <p className="integrity-card-desc d-none d-md-block">
                    Because you consume in off-peak hours, electric companies
                    bill you less, resulting in huge savings.
                  </p>
                </div>
                <p className="integrity-card-desc-m d-md-none">
                  Because you consume in off-peak hours, electric companies bill
                  you less, resulting in huge savings.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 integrity-card-wrap">
              <img
                className="arrow-2 arrow"
                src="landing-assets/thermostat/img/inte/arrow-2.png"
                alt="arrow"
              />
              <img
                className="arrow-3 arrow"
                src="landing-assets/thermostat/img/inte/arrow-3.png"
                alt="arrow"
              />

              <div className="integrity-card integrity-card-3">
                <div className="integrity-card-icon">
                  <img
                    src="landing-assets/thermostat/img/inte/power.png"
                    alt="icon"
                  />
                </div>
                <div className="integrity-card-text">
                  <h4 className="integrity-card-title">Optiwatt</h4>
                  <p className="integrity-card-desc d-none d-md-block">
                    Companies give a portion of the savings to Optiwatt,
                    allowing us to provide awesome features to you.
                  </p>
                </div>
                <p className="integrity-card-desc-m d-md-none">
                  Companies give a portion of the savings to Optiwatt, allowing
                  us to provide awesome features to you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--integrity area end  --> */}
    </>
  )
}
