export default function CTA() {
  return (
    <>
      {/* <!--cta area start  --> */}
      <section className="cta">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-lg-6">
              <div className="cta-title-area">
                <h3 className="cta-title">Ready to get started?</h3>
                <p className="cta-des">Optiwatt is free. Forever.</p>
              </div>
            </div>
            <div className="col-md-7 col-lg-6">
              <div className="cta-rating-wrap">
                <div className="cta-rating">
                  <span className="rating-title">4.8 Star rating</span>
                  <div className="starts">
                    <span>
                      <i className="fas fa-star"></i>
                    </span>
                    <span>
                      <i className="fas fa-star"></i>
                    </span>
                    <span>
                      <i className="fas fa-star"></i>
                    </span>
                    <span>
                      <i className="fas fa-star"></i>
                    </span>
                    <span>
                      <i className="fas fa-star"></i>
                    </span>
                  </div>
                  <span className="download-title">150,000 App downloads</span>
                </div>
                <a href="signup" className="cta-signup-btn">
                  Sign up now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--cta area end  --> */}
    </>
  )
}
