import { ThermostatLandingLayout } from '@/app/layouts'
import { useUnsetBodyOverflow } from '@/app/hooks'
import Banner from './sections/Banner'
import Statement from './sections/Statement'
import LazyLoad from 'react-lazy-load'
import BrandCarousel from './sections/BrandCarousel'
import './index.css'
import AllFeatures from './sections/AllFeatures'
import HowItWorks from './sections/HowItWorks'
import CTA from './sections/CTA'
import Integrity from './sections/Integrity'
import FAQ from './sections/FAQ'

export default function HomePage() {
  useUnsetBodyOverflow('auto')

  return (
    <ThermostatLandingLayout>
      <Banner />
      <LazyLoad offset={100}>
        <Statement />
      </LazyLoad>
      <BrandCarousel />
      <LazyLoad offset={200}>
        <AllFeatures />
      </LazyLoad>
      <LazyLoad offset={200}>
        <HowItWorks />
      </LazyLoad>
      <LazyLoad offset={100}>
        <CTA />
      </LazyLoad>
      <LazyLoad offset={100}>
        <Integrity />
      </LazyLoad>
      <FAQ />
    </ThermostatLandingLayout>
  )
}
