export default function BrandCarousel() {
  return (
    <>
      {/*<!-- brand carousel area start --> */}
      <section className="brand-carousel-area">
        <div className="brand-items-wrapper owl-carousel">
          <div className="brand-item">
            <img src="landing-assets/thermostat/img/logo-c/1.webp" alt="logo" />
            <img src="landing-assets/thermostat/img/logo-c/2.webp" alt="logo" />
          </div>

          <div className="brand-item">
            <img src="landing-assets/thermostat/img/logo-c/3.webp" alt="logo" />
            <img src="landing-assets/thermostat/img/logo-c/4.webp" alt="logo" />
          </div>

          <div className="brand-item">
            <img src="landing-assets/thermostat/img/logo-c/5.webp" alt="logo" />
            <img src="landing-assets/thermostat/img/logo-c/6.webp" alt="logo" />
          </div>

          <div className="brand-item">
            <img src="landing-assets/thermostat/img/logo-c/7.webp" alt="logo" />
            <img src="landing-assets/thermostat/img/logo-c/8.webp" alt="logo" />
          </div>

          <div className="brand-item">
            <img src="landing-assets/thermostat/img/logo-c/9.webp" alt="logo" />
            <img
              src="landing-assets/thermostat/img/logo-c/10.webp"
              alt="logo"
            />
          </div>

          <div className="brand-item">
            <img
              src="landing-assets/thermostat/img/logo-c/11.webp"
              alt="logo"
            />
            <img
              src="landing-assets/thermostat/img/logo-c/12.webp"
              alt="logo"
            />
          </div>

          <div className="brand-item">
            <img
              src="landing-assets/thermostat/img/logo-c/13.webp"
              alt="logo"
            />
            <img
              src="landing-assets/thermostat/img/logo-c/14.webp"
              alt="logo"
            />
          </div>

          <div className="brand-item">
            <img
              src="landing-assets/thermostat/img/logo-c/15.webp"
              alt="logo"
            />
            <img
              src="landing-assets/thermostat/img/logo-c/16.webp"
              alt="logo"
            />
          </div>

          <div className="brand-item">
            <img
              src="landing-assets/thermostat/img/logo-c/17.webp"
              alt="logo"
            />
            <img
              src="landing-assets/thermostat/img/logo-c/18.webp"
              alt="logo"
            />
          </div>

          <div className="brand-item">
            <img src="landing-assets/thermostat/img/logo-c/7.webp" alt="logo" />
            <img src="landing-assets/thermostat/img/logo-c/5.webp" alt="logo" />
          </div>
        </div>
      </section>
      {/* <!-- brand carousel area end --> */}
    </>
  )
}
