import ContactForm from '@/app/layouts/ThermostatLanding/ContactForm'
import { FAQAccordion } from '../../faq'

const FAQQuestions = [
  {
    question: 'Is Optiwatt secure? Is my password safe?',
    answer:
      'Optiwatt never stores your credentials on our servers and your data is 100% safe. When you connect Optiwatt to your thermostat account using your login credentials, we receive an authorization token that can be used to communicate directly with your device. Optiwatt then stores an encrypted version of that authentication token and discards any sensitive data.',
  },
  {
    question: 'What thermostat makes and models does Optiwatt support?',
    answer:
      'Optiwatt currently supports all internet-connected smart thermostats manufactured by Google Nest, ecobee, and Honeywell. We add new makes and models on a regular basis, so stay tuned for updates!',
  },
  {
    question: 'Does Optiwatt control my thermostat’s schedule?',
    answer:
      'No. Optiwatt only adjusts your thermostat’s temperature manually, as if you were turning the dial on the wall. Your normal schedule will resume shortly thereafter. You must make changes to your thermostat’s schedule in your manufacturer’s app, using Optiwatt’s cost insights to guide you. We hope to support smart scheduling very soon, so stay tuned for updates!',
  },
  {
    question: 'Is this different from my thermostat manufacturer’s app?',
    answer:
      "Yes, Optiwatt's technology allows you to see how much your heating or air conditioning will cost you in real-time. You can tweak temperature within the app, just as you would on the wall, and Optiwatt will estimate what you will save or spend as a result. Optiwatt also estimates how much you are likely to spend throughout the year as the outside temperature changes, and provides historical spending breakdowns by time period or comfort setting so you can better understand your energy bill.",
  },
  {
    question: 'Do I have to change the temperature in the Optiwatt app?',
    answer:
      'No, you can always change the temperature on the wall or in your manufacturer’s app, and then check in with Optiwatt to see how the change impacted your wallet. Optiwatt will never change your temperature setpoint unless you do.',
  },
  {
    question: 'How does the cost estimation work?',
    answer:
      'Optiwatt uses your home address to find the size of your home, its age, the outside temperature, and other relevant data points. This is used to calculate the thermal efficiency of your insulation, the daily losses that occur as a result of heat exchange, and the output of your heater(s) and/or air conditioner(s) that keep you comfortable. If you feel your costs are inaccurate, you can always contact Optiwatt’s customer support to update your profile.',
  },
  {
    question:
      'Does Optiwatt estimate natural gas, heating oil, or other costs associated with keeping my home comfortable?',
    answer:
      'No, Optiwatt only estimates the electrical costs associated with heating and cooling. During winter months, if you use natural gas or other combustible fuels to heat your home, Optiwatt will only show you the electrical cost associated with running the fans in these systems.',
  },
  {
    question: 'Does this work outside of the USA?',
    answer: 'Yes, we support all countries and currencies.',
  },
  {
    question: 'How does Optiwatt make money?',
    answer:
      "Our goal is to work with utility companies and reduce peak-load demand by helping you schedule your energy consumption during off-peak hours. This will save utilities money by preventing additional 'peaker' power plants from needing to be fired up.",
  },
  {
    question: 'How does this save earth?',
    answer:
      'Utility companies are forced to turn on environmentally unfriendly fossil fuel based power plants during times of peak demand. Optiwatt will help you to consume energy during off-peak hours when your rates are lowest, therefore shifting peak load and preventing these coal generators from ever being needed.',
  },
]

export default function FAQ() {
  return (
    <>
      {/*<!--faq area start  --> */}
      <section className="faq section-padding">
        <div className="container">
          <h4 className="sub-heading">FAQ</h4>
          <h2 className="section-heading">We’re here to help!</h2>

          <div className="row">
            <div className="col-lg-2 col-md-1"></div>
            <div className="col-lg-8 col-md-10">
              <FAQAccordion questions={FAQQuestions} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-3"></div>
            <div className="col-lg-4 col-md-6">
              <div className="faq-form">
                <h3 className="faq-form-heading">
                  Don’t see your question? Ask us here:
                </h3>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--faq area end  --> */}
    </>
  )
}
