import useAddScripts from '@/utils/dom/useAddScripts'

export default function HowItWorks() {
  useAddScripts(['landing-assets/thermostat/js/howitworks.js'])
  return (
    <>
      {/* <!-- =========================== -->
      <!--how it work area start  --> */}
      <section className="section how-it-works">
        <div className="section-content">
          <div className="group group-0">
            <div className="item item-0 item-list photos">
              <ul className="list">
                <li id="hiw1">
                  <div className="scroll-snap">
                    <div className="bg-helper-container">
                      <div
                        className="bg-helper"
                        style={{
                          backgroundImage:
                            'url(/landing-assets/thermostat/img/thermostat-usage.webp)',
                        }}
                      ></div>
                    </div>
                  </div>
                </li>
                <li id="hiw2">
                  <div className="scroll-snap">
                    <div className="bg-helper-container">
                      <div
                        className="bg-helper"
                        style={{
                          backgroundImage:
                            'url(/landing-assets/thermostat/img/modern-house.webp)',
                        }}
                      ></div>
                    </div>
                  </div>
                </li>
                <li id="hiw3">
                  <div className="scroll-snap">
                    <div className="bg-helper-container">
                      <div
                        className="bg-helper"
                        style={{
                          backgroundImage:
                            'url(/landing-assets/thermostat/img/relax-phone.webp)',
                        }}
                      ></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="group group-1">
            <div className="item item-0 item-list steps">
              <ul className="list">
                <li>
                  <div className="group group-0">
                    <div className="item item-0 item-text subtitle">
                      <p className="text-content">STEP 1</p>
                    </div>
                    <div className="item item-1 item-text title">
                      <p className="text-content">Connect your thermostat</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="group group-0">
                    <div className="item item-0 item-text subtitle">
                      <p className="text-content">STEP 2</p>
                    </div>
                    <div className="item item-1 item-text title">
                      <p className="text-content">Connect your utility</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="group group-0">
                    <div className="item item-0 item-text subtitle">
                      <p className="text-content">STEP 3</p>
                    </div>
                    <div className="item item-1 item-text title">
                      <p className="text-content">Start saving & tracking</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="item item-1 item-text title note">
              <p className="text-content">
                Connect to as many thermostats as you want safely with
                end-to-end encryption.
              </p>
              <p className="text-content pa">
                Sign in to your utility provider account to connect to Optiwatt
              </p>
              <p className="text-content pa">
                Sit back and watch Optiwatt save you money every month
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="how-it-work section-padding">
        <div className="work-tab-bg-img-wrap">
          <div className="work-tab-bg-img active-fade-content tab-1">
            <img
              src="landing-assets/thermostat/img/how-work/bg-1.jpg"
              alt="work bg"
            />
          </div>

          <div className="work-tab-bg-img tab-2">
            <img
              src="landing-assets/thermostat/img/how-work/bg-2.jpg"
              alt="work bg"
            />
          </div>

          <div className="work-tab-bg-img tab-3">
            <img
              src="landing-assets/thermostat/img/how-work/bg-3.png"
              alt="work bg"
            />
          </div>
        </div>
        <div className="container">
          <div className="row flex-column justify-content-between">
            <div className="col-md-7 col-lg-7">
              <div className="how-it-work-nav">
                <div className="progress-bar-wrap">
                  <div className="progressbar"></div>
                </div>
                <div className="work-nav-item active-tab-btn" data-tab="tab-1">
                  <span className="work-step">STEP 1</span>
                  <h4 className="work-item-title">Connect your thermostat</h4>
                </div>
                <div className="work-nav-item" data-tab="tab-2">
                  <span className="work-step">STEP 2</span>
                  <h4 className="work-item-title">Connect your utility</h4>
                </div>
                <div className="work-nav-item" data-tab="tab-3">
                  <span className="work-step">STEP 3</span>
                  <h4 className="work-item-title">Start saving & tracking</h4>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 work-tabs-wrapper">
              <h2 className="section-heading work-tab-item active-fade-content tab-1">
                Connect to as many thermostats as you want safely with
                end-to-end encryption.
              </h2>
              <h2 className="section-heading work-tab-item tab-2">
                Sign in to your utility provider account to connect to Optiwatt
              </h2>
              <h2 className="section-heading work-tab-item tab-3">
                Sit back and watch Optiwatt save you money every month
              </h2>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!--how it work area end  --> */}
    </>
  )
}
