import useAddScripts from '@/utils/dom/useAddScripts'

export default function AllFeatures() {
  useAddScripts(['landing-assets/thermostat/js/allfeatures.js'])
  return (
    <div className="all-feature-wrapper">
      <div className="feature-bg-shape">
        <img src="landing-assets/thermostat/img/bg-shape-2.png" alt="" />
      </div>
      {/* <!-- feature area start --> */}
      <section className="feature">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-6">
              <div className="feature-text-content">
                <h2 className="section-heading">
                  <span>
                    See what it costs to be comfortable at home.&nbsp;
                  </span>
                  <span className="gradient-title">
                    Put dollars to degrees.
                  </span>
                </h2>

                <p className="desc">
                  Real-time home energy costs synced to your smart thermostat
                  usage.
                </p>
                <div className="callout">
                  <img
                    src="landing-assets/thermostat/img/feature/lock.png"
                    alt="lock"
                  />
                  <p className="callout-desc">
                    Connect your smart thermostat securely with end-to-end
                    encryption
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 p-s-none">
              <div className="feature-graphic">
                <div className="feature-thum">
                  <img
                    src="landing-assets/thermostat/img/feature/feature.jpg"
                    alt="feature"
                  />
                </div>
                <div className="feature-blocks">
                  <img
                    src="landing-assets/thermostat/img/feature/2.png"
                    alt=""
                  />
                  <img
                    src="landing-assets/thermostat/img/feature/1.png"
                    alt=""
                  />
                  <img
                    src="landing-assets/thermostat/img/feature/3.png"
                    alt=""
                  />
                </div>
                <div className="exclusive-offer">
                  <img
                    src="landing-assets/thermostat/img/feature/exclusive.png"
                    alt="exclusive img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- feature area end -->
        <!-- =========================== -->
        <!-- feature area 2 start --> */}
      <section className="feature-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-7 order-2 order-md-0 p-s-none">
              <div className="feature-2-graphic">
                <img
                  src="landing-assets/thermostat/img/feature/feture=2-thum.png"
                  className="d-none d-md-block"
                  alt=""
                />
                <img
                  src="landing-assets/thermostat/img/feature/feture=2-thum2.png"
                  className="d-md-none"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="feature-2-text-content">
                <h2 className="section-heading">
                  <span className="gradient-title">
                    Fix your schedule settings
                  </span>
                  &nbsp;to fit your lifestyle.
                </h2>
                <p className="desc">
                  Balance comfort and cost by eliminating daily wasted energy
                </p>
                <div className="callout">
                  <img
                    src="landing-assets/thermostat/img/feature/print.png"
                    alt="print"
                  />
                  <p className="callout-desc">
                    Optiwatt analyzes your home and local weather to enable
                    smarter savings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- feature area 2 end -->
        <!-- =========================== -->
        <!-- =========================== -->
        <!-- feature area 3 start --> */}
      <section className="feature-3 section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-lg-6">
              <div className="feature-3-text-content">
                <h2 className="section-heading">
                  Savings, <br />
                  <span className="gradient-title"> beyond your HVAC...</span>
                </h2>
                <p className="desc">
                  Have an EV? Unlock even more savings than with your current
                  scheduler app.
                </p>
                <a href="/" className="learn-about-btn">
                  <span>Learn about</span> Optiwatt EV™
                  <i className="fas fa-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 p-s-none">
              <div className="feature-3-graphic">
                <img
                  src="landing-assets/thermostat/img/feature/feature3.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- feature area 3 end -->
        <!-- =========================== -->
        <!--more feature area start  --> */}
      <section className="more-feature section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 col-lg-3">
              <div className="more-feature-tabs">
                <h3 className="label">More Features</h3>
                <h2 className="section-heading">So much more to love</h2>
                <div className="more-feature-tabs-wrap tab-buttons">
                  <button className="tab-btn active-tab-btn" data-tab="tab-1">
                    Tweak & Track
                  </button>
                  <button className="tab-btn" data-tab="tab-2">
                    Schedule & Save
                  </button>
                  <button className="tab-btn" data-tab="tab-3">
                    Stay Informed
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-9">
              <div className="row align-items-center">
                <div className="col-lg-8 tab-thum-wrap">
                  <div className="more-feature-thum tab-1 active-fade-content">
                    <img
                      src="landing-assets/thermostat/img/more-feature/thum.png"
                      alt="Feature thum"
                    />
                  </div>
                  <div className="more-feature-thum tab-2">
                    <img
                      src="landing-assets/thermostat/img/more-feature/thum-2.png"
                      alt="Feature thum"
                    />
                  </div>
                  <div className="more-feature-thum tab-3">
                    <img
                      src="landing-assets/thermostat/img/more-feature/thum-3.png"
                      alt="Feature thum"
                    />
                  </div>
                </div>
                <div className="col-lg-4 text-contents">
                  <div className="callouts tab-1 active-fade-content">
                    <div className="callout-item">
                      <h4 className="callout-title">Cost Insights</h4>
                      <p className="callout-desc">
                        See what it costs to be comfortable. Optiwatt integrates
                        with your exact utility rates to show you what you will
                        spend or save, every time you turn the dial.
                      </p>
                    </div>
                    <div className="callout-item">
                      <h4 className="callout-title">Real-Time Feedback</h4>
                      <p className="callout-desc">
                        Don’t wait a month for a surprise utility bill to show
                        up. Get live updates on heating and air conditioning
                        costs, and stay ahead of your spending.
                      </p>
                    </div>
                    <div className="callout-item">
                      <h4 className="callout-title">In-App Control</h4>
                      <p className="callout-desc">
                        Deciding between “not quite” and “just right”? Play with
                        your thermostat’s temperature in the Optiwatt app, and
                        find a budget that works for you.
                      </p>
                    </div>
                  </div>
                  <div className="callouts tab-2">
                    <div className="callout-item">
                      <h4 className="callout-title">Schedule Breakdown</h4>
                      <p className="callout-desc">
                        Find the setting that’s costing you the most, and fix
                        it, with Optiwatt’s proprietary schedule analysis tool.
                        Save hundreds every year, without noticing a thing.
                      </p>
                    </div>
                    <div className="callout-item">
                      <h4 className="callout-title">Weather Prediction</h4>
                      <p className="callout-desc">
                        Savings are seasonal. Our software knows if it's going
                        to be sunny or snowy, so sit back, relax, and let us
                        plan ahead for you.
                      </p>
                    </div>
                    <div className="callout-item">
                      <h4 className="callout-title">Cost Estimates</h4>
                      <p className="callout-desc">
                        Look into the future, and what do you see? Savings, of
                        course. Find the right schedule for every month of the
                        year, before you forget.
                      </p>
                    </div>
                  </div>
                  <div className="callouts tab-3">
                    <div className="callout-item">
                      <h4 className="callout-title">Spending History</h4>
                      <p className="callout-desc">
                        Want to know why your utility bill changed? Break down
                        your spending day by day, and see the cost of comfort
                        fluctuate.
                      </p>
                    </div>
                    <div className="callout-item">
                      <h4 className="callout-title">Heating vs Cooling</h4>
                      <p className="callout-desc">
                        Hot one day, freezing the next, weather changes rapidly.
                        See how your air conditioning, heating, and adjustment
                        costs stack up in color coordinated fashion.
                      </p>
                    </div>
                    <div className="callout-item">
                      <h4 className="callout-title">Environmental Factors</h4>
                      <p className="callout-desc">
                        We help you remember why last Tuesday tripled in price.
                        Historical temperature data shows it was a heatwave, and
                        you ran the AC all night long. Worth it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--more feature area end --> */}
    </div>
  )
}
