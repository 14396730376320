const Testmonials = [
  {
    name: 'Cade M.',
    testimony:
      'Been using the app for nearly a month now and it honestly left me speechless!!! I never write reviews for apps but this one was worth every penny, but best part is that it’s FREE. I appreciate the frequent updates that come out to improve the app! I love when companies really work on the small details!',
    image: 'landing-assets/thermostat/img/testimonial/1.jpg',
  },
  {
    name: 'Casey B.',
    testimony:
      'Exactly what I needed! I’ve been looking for an app to track my home power usage since I live with roommates and want to track my usage. There are several other apps that do this but all charge fees, this app does it wonderfully and gives lots of other helpful information like the electricity price based on your power bill, and how much you’ve saved.',
    image: 'landing-assets/thermostat/img/testimonial/2.jpg',
  },
  {
    name: 'NS Speed',
    testimony:
      'I have been using this app for about four months now and it is pretty awesome. There is a feature where you can allow the app to manage charges, and additional options that you can select that say the app will only allow when your power grid is using clean electricity and/or when electricity is at the cheapest rate. I would recommend.',
    image: 'landing-assets/thermostat/img/testimonial/3.png',
  },
  {
    name: 'Sean M.',
    testimony:
      'Great app, does what it is designed for and does it for free. Easiest way to track what you have spent. Customer support is next level. Developers are responsive and did a great job helping me correct my rates.',
    image: 'landing-assets/thermostat/img/testimonial/4.jpg',
  },
  {
    name: 'John-Paul L.',
    testimony:
      "So far I'm really enjoying this app. I reached out to support and they were happy to make adjustments to my account. I highly recommend this app. Great features and greater support!",
    image: 'landing-assets/thermostat/img/testimonial/6.png',
  },
  {
    name: 'Jordan C.',
    testimony:
      'Easy sign up and set up, clean user interface, nice stats on environmental impact and money spent.',
    image: 'landing-assets/thermostat/img/testimonial/5.jpg',
  },
]

export default function Banner() {
  return (
    <>
      <section className="banner-area">
        <div className="banner-shape">
          <img
            src="landing-assets/thermostat/img/banner/banner-shape.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5">
              <div className="banner-left">
                <h1 className="banner-heading">
                  Smart Thermostats.
                  <span className="gradient-title">Now Smarter.</span>
                </h1>
                <p className="sub-heading">
                  Live comfortably for the lowest possible price.
                </p>
                <div className="banner-btns">
                  <a href="signup" target="_blank" className="prim-btn">
                    Get Started
                  </a>
                  <a href="download" className="sec-btn">
                    Get the App <i className="fas fa-chevron-right"></i>
                  </a>
                </div>

                <div className="mobile-banner banner-slider d-md-none">
                  <img
                    src="landing-assets/thermostat/img/banner/banner-img.webp"
                    alt="Banner img"
                  />
                  <img
                    src="landing-assets/thermostat/img/banner/slide-2.webp"
                    alt="Banner img"
                  />
                  <img
                    src="landing-assets/thermostat/img/banner/slide-3.webp"
                    alt="Banner img"
                  />
                </div>

                <div className="rating-area">
                  <div className="rating-item">
                    <div className="value">3k</div>
                    <div className="title">RATINGS</div>
                  </div>
                  <div className="rating-item">
                    <div className="value">4.8</div>
                    <div className="stars">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                  <div className="rating-item">
                    <div className="value">150k</div>
                    <div className="title">DOWNLOADS</div>
                  </div>
                </div>
                <div className="testimonial d-none d-lg-block">
                  <div className="testimonial-content-wrapper">
                    {Testmonials.map(({ name, testimony }, index) => (
                      <div className="testimonial-content" key={index}>
                        <p>{testimony}</p>
                        <h4 className="person-name">{name}</h4>
                      </div>
                    ))}
                  </div>
                  <div className="testimonial-tabs">
                    {Testmonials.map(({ image }, index) => (
                      <div className="tes-tab" key={index}>
                        <img src={image} alt="Client Img" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7">
              <div className="banner-right banner-slider d-none d-md-block">
                <img
                  src="landing-assets/thermostat/img/banner/banner-img.webp"
                  alt="Banner img"
                />
                <img
                  src="landing-assets/thermostat/img/banner/slide-2.webp"
                  alt="Banner img"
                />
                <img
                  src="landing-assets/thermostat/img/banner/slide-3.webp"
                  alt="Banner img"
                />
              </div>
            </div>
            <div className="col-12 d-lg-none">
              <div className="testimonial">
                <div className="testimonial-content-wrapper">
                  {Testmonials.map(({ name, testimony }, index) => (
                    <div className="testimonial-content" key={index}>
                      <p>{testimony}</p>
                      <h4 className="person-name">{name}</h4>
                    </div>
                  ))}
                </div>
                <div className="testimonial-tabs">
                  {Testmonials.map(({ image }, index) => (
                    <div className="tes-tab" key={index}>
                      <img src={image} alt="Client Img" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
